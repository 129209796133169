<template>
  <div>
    <div>
      <v-banner
        class="accent white--text mb-3 font-weight-black"
        v-if="ordersData.length != 0"
      >
        <div class="d-flex align-center justify-center white--text ma-3">
          <div class="mr-2">
            <v-icon class="white--text">mdi-information</v-icon>
          </div>
          {{ $t("message.myTicketsPage.orderInProcessInfo") }}
        </div>
      </v-banner>
    </div>
    <div class="order-history-wrap emb-card pa-4" v-if="ordersData.length != 0">
      <v-data-table
        :headers="ordersHeaders"
        :items="filteredItems"
        hide-default-footer
        disable-sort
        :items-per-page="-1"
      >
        <template v-slot:item.receipt_id="{ item }">
          <div v-if="item.receipt_id != undefined">
            {{ item.receipt_id }}
          </div>
          <div v-if="item.stripe_payment_invoice != undefined">
            {{ item.stripe_payment_invoice.replace("in_", "") }}
          </div>
        </template>
        <template v-slot:item.order_date="{ item }">
          {{ formatDate(item.order_date) }}
        </template>
        <template v-slot:item.total_amount_currency.amount="{ item }">
          {{
            $formatMoney(
              item.total_amount_currency.amount,
              2,
              item.total_amount_currency.currency,
              1
            )
          }}
        </template>
        <template v-slot:item.total_amount="{ item }">
          {{ $formatMoney(item.total_amount, 2, "USD") }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ statusDescription(item.status, item.order_type) }}
        </template>
        <template v-slot:item.credit="{ item }">
          <div v-if="item.order_type == 'INTERAC'">
            {{ $formatMoney(item.credit, 2, "USD") }}
          </div>
          <div v-if="item.order_type == 'STRIPE'">
            {{ $formatMoney(item.total_amount_currency.credit, 2, "USD") }}
          </div>
        </template>
        <template v-slot:item.coupon="{ item }">
          <div v-if="item.coupon != undefined && item.coupon != ''">
            {{
              item.coupon +
              " (" + $t("message.myTicketsPage.rebate") + " " +
              $formatMoney(item.coupon_amount, 2, "USD") +
              ")"
            }}
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:item.action="{ item }">
          <div v-if="item.status == 'SOLD' && item.order_type != 'STRIPE'">
            <v-btn class="accent" @click="cancelOrder(item)">
              {{ $t("message.cancel") }}
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="order-history-wrap emb-card pa-4" v-else>
      {{ $t("message.myTicketsPage.noOrder") }}
    </div>
    <emb-delete-confirmation
      ref="cancelOrderConfirmationDialog"
      :message="$t('message.myTicketsPage.cancelOrder')"
      @onConfirm="onCancelOrder"
    >
    </emb-delete-confirmation>
  </div>
</template>
<style scoped>
.banner_color {
  background-color: #f5b841;
}
</style>
<script>
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getUser", "getUserInfo"]),
    filteredItems() {
      return this.ordersData.filter((i) => {
        return (
          i.status != "CANCELED" &&
          i.status != "VOID" &&
          i.status != "STRIPE_CHECKOUT"
        );
      });
    },
  },
  props: ["exclude"],
  data() {
    return {
      orders_fetched: false,
      ordersHeaders: [],
      ordersData: [],
      orderToCancel: null,
    };
  },
  watch: {
    "$store.state.user": function () {
      if (this.getUser != undefined && !this.orders_fetched) {
        this.fetch_user_orders();
      }
    },
    "$i18n.locale": function () {
      this.updateHeaders();
    },
  },
  mounted() {
    if (this.getUser != undefined && !this.orders_fetched) {
      this.fetch_user_orders();
    }
    this.updateHeaders();
  },
  methods: {
    fetch_user_orders() {
      const ordersRef = collection(getFirestore(), "orders");
      let q = query(ordersRef, where("client_id", "==", this.getUser.uid));

      if (this.exclude != undefined) {
        q = query(
          ordersRef,
          where("client_id", "==", this.getUser.uid),
          where("status", "!=", this.exclude),
          orderBy("status", "asc")
        );
      } else {
        q = query(
          ordersRef,
          where("client_id", "==", this.getUser.uid),
          orderBy("order_date", "desc")
        );
      }

      getDocs(q).then((querySnapshot) => {
        this.orders_fetched = true;
        var documents = [];
        querySnapshot.forEach((doc) => {
          documents.push(doc.data());
        });
        this.ordersData = documents;
      });
    },
    formatDate(timestamp) {
      var d = timestamp.toDate();
      const ye = new Intl.DateTimeFormat(this.getUserInfo.language, {
        year: "numeric",
      }).format(d);
      const mo = new Intl.DateTimeFormat(this.getUserInfo.language, {
        month: "short",
      }).format(d);
      const da = new Intl.DateTimeFormat(this.getUserInfo.language, {
        day: "2-digit",
      }).format(d);
      return `${da}-${mo}-${ye}`;
    },
    statusDescription(status, order_type) {
      if (status == "SOLD" && order_type == "STRIPE") {
        return this.$t("message.myTicketsPage.statusDesc.sold_stripe");
      } else if (status == "SOLD") {
        return this.$t("message.myTicketsPage.statusDesc.sold");
      } else if (status == "ASSIGNED") {
        return this.$t("message.myTicketsPage.statusDesc.assigned");
      } else if (status == "COMPLETED") {
        return this.$t("message.myTicketsPage.statusDesc.completed");
      } else if (status == "CANCELED") {
        return this.$t("message.myTicketsPage.statusDesc.canceled");
      }
    },
    updateHeaders() {
      this.ordersHeaders = [
        {
          text: this.$t("message.myTicketsPage.header.receiptNumber"),
          value: "receipt_id",
        },
        {
          text: this.$t("message.myTicketsPage.header.price"),
          value: "total_amount",
        },
        {
          text: this.$t("message.myTicketsPage.header.priceCurrency"),
          value: "total_amount_currency.amount",
        },
        {
          text: this.$t("message.myTicketsPage.header.credit"),
          value: "credit",
        },
        {
          text: this.$t("message.myTicketsPage.header.coupon"),
          value: "coupon",
        },
        {
          text: this.$t("message.myTicketsPage.header.status"),
          value: "status",
        },
        {
          text: this.$t("message.myTicketsPage.header.orderDate"),
          value: "order_date",
        },

        {
          text: "",
          value: "action",
        },
      ];
    },
    cancelOrder(order) {
      this.orderToCancel = order;
      this.$refs.cancelOrderConfirmationDialog.openDialog();
    },
    onCancelOrder() {
      this.$refs.cancelOrderConfirmationDialog.close();
      this.$store
        .dispatch("update_order", {
          update_values: { general_status: "CANCELED", status: "CANCELED" },
          docId: this.orderToCancel.id,
        })
        .then(() => {
          this.fetch_user_orders();
        });
      this.orderToCancel = null;
    },
    // customFilter(items, search, filter) {
    //   console.log("search:", search);
    //   console.log("filter:", filter);
    //   return items.status != "CANCELED";
    //   // search = search.toString().toLowerCase();
    //   // return items.filter((row) => filter(row["status"], search));
    // },
  },
};
</script>

